export const PAGE_DATA = {
  forms: [
    {
      title: "Builder Leave Form",
      description: "Fill this form to apply leave on your builder project.",
      url: "https://docs.google.com/forms/d/e/1FAIpQLSdg177yQESpBSxk59-jrGMKR0ERdBE30HRv8E-bXORAzv_FQg/viewform?usp=sf_link",
      buttonText: "Click to apply leave",
    },
    {
      title: "Zoho Leave Form",
      description: "Fill this form to apply leave on Zoho ",
      url: "https://accounts.zoho.in/signin",
      buttonText: "Click to apply leave",
    },
  ],
  reporting: [
    {
      title: "General Feedback Form",
      description: "Fill this form if you want to share any feedback for us.",
      url: "https://forms.gle/dfipJMV13jg8YmUz9",
      buttonText: "Click here",
    },
    {
      title: "Anonymous Feedback Form",
      description:
        "Fill this form if you want to share some feedback anonymously.",
      url: "https://forms.gle/T1aqBKY9ECg8xS2w8",
      buttonText: "Click here",
    },
    {
      title: "Metafic Tools Bug Reporting",
      description:
        "Found a bug in any of the tool (Allocator etc.)? Feel free to report it here.",
      url: "https://forms.gle/491SST7yoNy6Ym5L6",
      buttonText: "Click here",
    },
  ],
  projects: [
    {
      title: "Allocator",
      description: "View details about client projects",
      url: "https://allocator.metafic.co",
      buttonText: "Click here",
    },
    {
      title: "GitHub",
      description: "View our internal & public projects",
      url: "https://github.com/metafic-co",
      buttonText: "Click here",
    },
  ],
  tools: [
    {
      title: "Metafic Vault",
      description: "Save your passwords with Vault, Developed by TeaM.",
      url: "https://vault.metafic.co",
      buttonText: "Click here",
    },
    {
      title: "Metafic Docs",
      description: "Get all the documentation at one place.",
      url: "https://docs.metafic.co",
      buttonText: "Click here",
    },
    {
      title: "Uptime",
      description: "View the status of all our live services and tools",
      url: "https://uptime.metafic.co/status/all",
      buttonText: "Click here",
    },
  ],
  socialHandles: [
    {
      title: "Metafic",
      description: "Visit our website",
      url: "https://metafic.co",
      buttonText: "Click here",
    },
    {
      title: "Facebook",
      description: "Follow us on Facebook",
      url: "https://www.facebook.com/people/Metafic/100069124335140/",
      buttonText: "Click here",
    },
    {
      title: "YouTube",
      description: "Subscribe to our YouTube Channel",
      url: "https://www.youtube.com/channel/UCDvuRbA9CIpi7CXvyo8FBxg",
      buttonText: "Click here",
    },

    {
      title: "Instagram",
      description: "Follow us on Instagram",
      url: "https://www.instagram.com/metafic.co/",
      buttonText: "Click here",
    },

    {
      title: "Twitter",
      description: "Follow us on Twitter",
      url: "https://twitter.com/metafic_co",
      buttonText: "Click here",
    },
    {
      title: "Linkedin",
      description: "Get updates on Linkedin",
      url: "https://www.linkedin.com/company/metafic/",
      buttonText: "Click here",
    },
  ],
};
