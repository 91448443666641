import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Login from "./components/Login";

function App(props) {
  return (
    <>
      <Routes>
        <Route element={<Dashboard />} path="/dashboard/*" />
        <Route element={<Login />} path="/" />
      </Routes>
    </>
  );
}

export default App;
