import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle, auth } from "../services/firebase";

const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();

  const [user, setUser] = React.useState(null);
  const [authToken, setAuthToken] = React.useState(null);

  const isValidEmail = (email) => {
    return email.split("@")[1] === "metafic.co";
  };
  const handleSignIn = () => {
    signInWithGoogle()
      .then(({ user }) => {
        if (user && isValidEmail(user.email)) {
          setUser(user);
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/dashboard/forms");
        } else {
          // toast.error("Something went wrong. Please signin with Metafic Email");
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  const isLoggedIn = React.useCallback(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user && isValidEmail(user.email)) {
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        getAuthToken();
        navigate("/dashboard/forms");
      }
    });
  }, [navigate]);
  const getAuthToken = () => {
    auth.currentUser?.getIdToken().then((token) => {
      setAuthToken(token);
      localStorage.setItem("authToken", token);
    });
  };

  React.useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(./metafic-dashboard.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#FB0F26" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              style={{ fontFamily: "Lexend Deca" }}
            >
              Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                style={{ background: "#FB0F26" }}
                sx={{ mt: 3, mb: 2 }}
                startIcon={<GoogleIcon />}
                onClick={() => handleSignIn()}
              >
                Sign in with Google
              </Button>

              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
