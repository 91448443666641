import React from "react";

import Cards from "./Cards";
import { Grid, Container } from "@mui/material";
import { PAGE_DATA } from "../Constants/Constants";

export default function Forms() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={4} style={{ marginTop: "30px" }}>
          {PAGE_DATA.forms.map((_Data) => {
            return (
              <>
                <Grid item xs={12} sm={6}>
                  <Cards
                    title={_Data.title}
                    url={_Data.url}
                    description={_Data.description}
                    buttonText={_Data.buttonText}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Container>
    </>
  );
}
