import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./DashboardSidbarList";
import Button from "@mui/material/Button";
import BugReportIcon from "@mui/icons-material/BugReport";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import BuildIcon from "@mui/icons-material/Build";
import PublicIcon from "@mui/icons-material/Public";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import { signInWithGoogle, auth } from "../services/firebase";

import Forms from "./Forms";
import Login from "./Login";
import BugReports from "./BugReports";
import Projects from "./Projects";
import SocialHandles from "./SocialHandles";
import Tools from "./Tools";
import Footer from "./Footer";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = React.useState(null);

  React.useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("user"));

    setUserData(userData);
  }, []);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          open={open}
          style={{ background: "#ed202e" }}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Welcome
            </Typography>
            <Button
              variant="text"
              color="inherit"
              onClick={async () => {
                try {
                  await auth.signOut();

                  navigate("/");
                } catch (error) {
                  console.error(error.message);
                }
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <a href="/">
              <img
                src="/Metafic-Logo.png"
                alt="Metafic Logo"
                style={{ height: "20px", marginRight: "40px" }}
              />
            </a>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={() => navigate("/dashboard/forms")}>
              <ListItemIcon>
                <InsertDriveFileIcon style={{ color: "#ed202e" }} />
              </ListItemIcon>
              <ListItemText primary="Leave Forms" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/dashboard/bugreporting")}>
              <ListItemIcon>
                <BugReportIcon style={{ color: "#ed202e" }} />
              </ListItemIcon>
              <ListItemText primary="Bug Reporting" />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListItemButton onClick={() => navigate("/dashboard/projects")}>
              <ListItemIcon>
                <AccountTreeIcon style={{ color: "#ed202e" }} />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListItemButton onClick={() => navigate("/dashboard/tools")}>
              <ListItemIcon>
                <BuildIcon style={{ color: "#ed202e" }} />
              </ListItemIcon>
              <ListItemText primary="Tools" />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListItemButton
              onClick={() => navigate("/dashboard/socialhandles")}
            >
              <ListItemIcon>
                <PublicIcon style={{ color: "#ed202e" }} />
              </ListItemIcon>
              <ListItemText primary="Social Handles" />
            </ListItemButton>
          </List>

          <List style={{ bottom: 0, position: "fixed" }}>
            <ListItemButton
              href="https://tea.m.metafic.co"
              target="_blank"
              style={{ paddingLeft: "5px" }}
            >
              <ListItemText
                primary={
                  <Typography variant="body2">
                    Tea
                    <span
                      style={{
                        color: "#ED202E",
                        fontWeight: "800",
                        paddingLeft: "2px",
                      }}
                    >
                      M.
                    </span>
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container>
            <Routes>
              <Route path="/forms" element={<Forms />} />
              <Route path="/bugreporting" element={<BugReports />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/socialhandles" element={<SocialHandles />} />
              <Route path="/tools" element={<Tools />} />
            </Routes>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  const navigate = useNavigate();
  const getAuthToken = () => {
    auth.currentUser?.getIdToken().then((token) => {
      localStorage.setItem("authToken", token);
    });
  };
  const isLoggedIn = React.useCallback(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
      } else {
        navigate("/");
      }
    });
  }, [navigate]);

  React.useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);
  return <DashboardContent />;
}
