import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function Cards(props) {
  const { title, url, description, buttonText } = props;
  return (
    <a href={url} target="_blank" style={{ textDecoration: "none" }}>
      <Card
        elevation={10}
        sx={{ minWidth: 275 }}
        style={{ borderRadius: "15px", cursor: "pointer" }}
        // onClick={() => {

        // }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: 18 }}
            style={{ fontFamily: "Lexend Deca" }}
            color="text.secondary"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            component={"div"}
            style={{ fontFamily: "Lexend Deca" }}
            gutterBottom
          >
            {description}
          </Typography>

          <CardActions style={{ paddingLeft: 0 }}>
            <Typography variant="button">{buttonText}</Typography>
          </CardActions>
        </CardContent>
      </Card>
    </a>
  );
}
